<template>
  <div class="left-center left-nav" v-bind:style="{ width: leftWidth + 'px' }">
    <div style="display: flex; justify-content: center">
      <img
        v-if="!status"
        class="logo"
        height="55"
        src="../img/logo1.png"
        width="155"
      />

      <img
        v-else
        class="logo2"
        height="60"
        src="../img/logo.png"
        style="padding: 5px"
        width="50"
      />
    </div>

    <el-menu
      :collapse="status"
      :collapse-transition="false"
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      router
      style="border: 0"
      @select="selectMenu"
    >
      <el-submenu v-if="this.role == 1" :class="subMenuClass" index="1">
        <template slot="title">
          <i class="icon-menu-overview"></i>
          <span class="item-title">基础设置</span>
        </template>
        <el-menu-item index="/slideShow"> 轮播图设置</el-menu-item>
        <el-menu-item index="/poster"> 海报设置</el-menu-item>
        <el-menu-item index="/startDiagram"> 启动图设置</el-menu-item>
        <el-menu-item index="/ruleDistribution"> 客户分配规则</el-menu-item>
        <el-menu-item index="/advertising"> 弹窗广告设置</el-menu-item>
        <el-menu-item index="/gift"> 礼物学币模板设置</el-menu-item>
        <el-menu-item index="/giftSet">礼物赠送配置</el-menu-item>
        <el-menu-item index="/avatarFrame">头像框配置</el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="this.role == 1 || this.role == 3"
        :class="subMenuClass"
        index="2"
      >
        <template slot="title">
          <i class="icon-menu-content-active"></i>
          <span class="item-title">内容</span>
        </template>
        <el-menu-item index="/excerpt"> 素材 </el-menu-item>
        <el-menu-item index="/fileDownload"> 资料下载 </el-menu-item>
      </el-submenu>
      <el-submenu v-if="this.role == 1" :class="subMenuClass" index="3">
        <template slot="title">
          <i class="icon-menu-course"></i>
          <span class="item-title">课堂</span>
        </template>
        <el-menu-item index="/course"> 我的课程</el-menu-item>
        <el-menu-item index="/checkCourse"> 打卡课程</el-menu-item>
        <el-menu-item index="/liveTrial"> 直播体验课</el-menu-item>
        <el-menu-item index="/liveUser"> 体验课用户</el-menu-item>
        <el-menu-item index="/liveOrder"> 直播体验订单</el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="
          this.role == 1 ||
          this.role == 2 ||
          this.role == 5 ||
          this.role == 4 ||
          this.role == 6
        "
        :class="subMenuClass"
        index="4"
      >
        <template slot="title">
          <i class="el-icon-s-order"></i>
          <span class="item-title">
            教学
            <i
              v-if="this.$store.state.user.info.not_answered_nums > 0"
              style="
                width: 5px;
                height: 5px;
                position: absolute;
                right: 10px;
                top: 10px;
                display: block;
                border-radius: 50%;
                background: #f00;
              "
            ></i>
          </span>
          <i
            v-if="this.$store.state.user.info.not_answered_nums > 0"
            style="
              width: 5px;
              height: 5px;
              position: absolute;
              right: -5px;
              top: 10px;
              display: block;
              border-radius: 50%;
              background: #f00;
            "
          ></i>
        </template>
        <el-menu-item index="/work"> 作业点评</el-menu-item>
        <el-menu-item index="/quiz">
          问题解答
          <span
            v-if="this.$store.state.user.info.not_answered_nums"
            style="float: right; color: red"
          >
            <el-tag size="mini" type="danger"
              ><b>{{
                this.$store.state.user.info.not_answered_nums > 99
                  ? "99+"
                  : this.$store.state.user.info.not_answered_nums
              }}</b></el-tag
            >
          </span></el-menu-item
        >
      </el-submenu>
      <el-submenu
        v-if="
          this.role == 1 ||
          this.role == 4 ||
          this.role == 6 ||
          this.role == 7 ||
          this.role == 8
        "
        :class="subMenuClass"
        index="5"
      >
        <template slot="title">
          <i class="icon-menu-manage"></i>
          <span class="item-title">用户</span>
        </template>
        <el-menu-item v-if="this.role == 1" index="/userList">
          用户列表
        </el-menu-item>

        <el-menu-item v-if="this.role == 1" index="/vipList">
          会员列表
        </el-menu-item>
        <el-menu-item index="/userSocial"> 用户动态</el-menu-item>

        <el-menu-item v-if="this.role == 1" index="/userMessage">
          消息通知
        </el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="this.role == 1 || this.role == 3"
        :class="subMenuClass"
        index="6"
      >
        <template slot="title">
          <i class="el-icon-folder"></i>
          <span class="item-title">资源素材</span>
        </template>
        <el-menu-item index="/file"> 文件</el-menu-item>
        <el-menu-item index="/video"> 视频</el-menu-item>
        <el-menu-item index="/audio"> 音频</el-menu-item>
        <el-menu-item index="/images">图片</el-menu-item>
      </el-submenu>
      <el-submenu v-if="this.role == 1" :class="subMenuClass" index="7">
        <template slot="title">
          <i class="icon-menu-application"></i>
          <span class="item-title">运营和营销</span>
        </template>
        <el-menu-item index="/extension"> 推广等级</el-menu-item>
        <el-menu-item index="/discount"> 优惠券</el-menu-item>
        <el-menu-item index="/vip"> 会员卡</el-menu-item>
        <el-menu-item index="/vipSet"> 会员设置</el-menu-item>
        <el-menu-item index="/promotion"> 组合购</el-menu-item>
        <!--				<el-menu-item index="/groupBuying"> 团购活动 </el-menu-item>-->
        <el-menu-item index="/adv"> 广告推荐</el-menu-item>
        <el-menu-item index="/aimActivity"> 限时活动</el-menu-item>
        <!--                            <el-menu-item index="/file">-->
        <!--                                <router-link to="/file">秒杀活动</router-link>-->
        <!--                            </el-menu-item>-->
        <!--                            <el-menu-item index="/file">-->
        <!--                                <router-link to="/file">团购活动</router-link>-->
        <!--                            </el-menu-item>-->
        <!--                            <el-menu-item index="/file">-->
        <!--                                <router-link to="/file">团购</router-link>-->
        <!--                            </el-menu-item>-->
      </el-submenu>
      <el-submenu v-if="this.role == 1" :class="subMenuClass" index="8">
        <template slot="title">
          <i class="icon-menu-mobile"></i>
          <span class="item-title">商城管理</span>
        </template>
        <el-menu-item index="/goods"> 商品列表</el-menu-item>
        <el-menu-item index="/order"> 订单列表</el-menu-item>
        <el-menu-item index="/stock"> 图书库存</el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="this.role == 1 || this.role == 5 || this.role == 33"
        :class="subMenuClass"
        index="9"
      >
        <template slot="title">
          <i class="el-icon-data-analysis"></i>
          <span class="item-title">
            销售管理
            <i
              v-if="
                this.$store.state.user.info.untreated_nums > 0 ||
                this.$store.state.user.info.untreated_data_files_nums > 0
              "
              style="
                width: 5px;
                height: 5px;
                position: absolute;
                right: 10px;
                top: 10px;
                display: block;
                border-radius: 50%;
                background: #f00;
              "
            ></i>
          </span>
          <i v-if="
                this.$store.state.user.info.untreated_nums > 0 ||
                this.$store.state.user.info.untreated_data_files_nums > 0
              "
             style="
                 width: 5px;
              height: 5px;
              position: absolute;
              right: -5px;
              top: 10px;
              display: block;
              border-radius: 50%;
              background: #f00;
              "
          ></i>
        </template>
<!--        <el-menu-item index="/customerCard"> 客户名片 </el-menu-item>-->
        <el-menu-item v-if="this.role == 1" index="/transfer">
          转移记录
        </el-menu-item>
        <el-menu-item v-if="this.role !== 33" index="/promote"> 推广用户</el-menu-item>
        <el-menu-item v-if="this.role !== 33" index="/promotion"> 组合购</el-menu-item>
        <el-menu-item index="/sales"> 报单</el-menu-item>
        <!--        <el-menu-item index="/dataExtract"> </el-menu-item>-->

        <el-menu-item index="/dataExtract" v-if="this.role !== 33">
          <!-- <el-badge :value="12" class="item">

                                                                                                                             权限变更
                                                                                                                                              </el-badge> -->
          资料提取
          <span style="float: right; color: red">
            <el-tag size="mini" type="danger"
              ><b>{{
                this.$store.state.user.info.untreated_data_files_nums > 99
                  ? "99+"
                  : this.$store.state.user.info.untreated_data_files_nums
              }}</b></el-tag
            >
          </span>
        </el-menu-item>
        <el-menu-item v-if="this.role !== 33" index="/expressage"> 快递查询</el-menu-item>
        <el-menu-item v-if="this.role !== 33" index="/salesUserList"> 用户列表</el-menu-item>
        <el-menu-item
          v-if="this.role === 1 || this.role === 5"
          index="/salesGroup"
        >
          销售组管理
        </el-menu-item>
        <el-menu-item
          v-if="this.role === 1 || this.role === 5"
          index="/salesGroupStatistics"
          >销售组统计
        </el-menu-item>
        <el-menu-item v-if="this.role == 1" index="/salesStatistics"
          >统计
        </el-menu-item>
        <el-menu-item v-if="this.role == 1" index="/salesStatisticsChart">
          统计图表
        </el-menu-item>
        <el-menu-item index="/power">
          <!-- <el-badge :value="12" class="item">
                                                    权限变更
                                                  </el-badge> -->
          权限变更
          <span style="float: right; color: red">
            <el-tag size="mini" type="danger"
              ><b>{{
                this.$store.state.user.info.untreated_nums > 99
                  ? "99+"
                  : this.$store.state.user.info.untreated_nums
              }}</b></el-tag
            >
          </span>
        </el-menu-item>
      </el-submenu>
      <el-submenu
        v-if="this.role == 1 || this.role == 6 || this.role == 2"
        :class="subMenuClass"
        index="10"
      >
        <template slot="title">
          <i class="el-icon-school"></i>
          <span class="item-title">教研中心</span>
        </template>
        <el-menu-item index="/excerpt"> 素材</el-menu-item>
        <el-menu-item index="/fileDownload"> 资料下载</el-menu-item>
        <el-menu-item index="/sales"> 报单</el-menu-item>
        <el-menu-item index="/teacherCourse"> 课程</el-menu-item>
        <el-menu-item index="/liveTrial"> 直播体验课</el-menu-item>
        <el-menu-item index="/question"> 题库</el-menu-item>
        <el-menu-item index="/annotation"> 批注积累</el-menu-item>
        <el-menu-item index="/knowledge_1"> 好词好句</el-menu-item>
        <el-menu-item index="/knowledge_2"> 知识扩展</el-menu-item>
        <el-menu-item index="/checkCourse"> 打卡课程</el-menu-item>
        <el-menu-item index="/teacherRecord"> 教师录单</el-menu-item>
        <el-menu-item index="/errorList"> 内容纠错</el-menu-item>
        <el-menu-item index="/evaluation"> 测评</el-menu-item>
        <el-menu-item index="/teacherGrouping"> 教师分组</el-menu-item>
      </el-submenu>
      <!--			<el-submenu index="11" :class="subMenuClass" v-if="this.role == 1">-->
      <!--				<template slot="title">-->
      <!--					<i class="icon-menu-course"></i>-->
      <!--					<span class="item-title">专栏</span>-->
      <!--				</template>-->
      <!--				<el-menu-item index="/specialColumn"> 专栏设置 </el-menu-item>-->
      <!--			</el-submenu>-->

      <el-submenu
        v-if="role === 1 || role === 5 || role === 6 || role === 2 || role == 33"
        :class="subMenuClass"
        index="12"
      >
        <template slot="title">
          <i class="el-icon-edit"></i>
          <span class="item-title">
            班级管理
            <i
              v-if="$store.state.user.info.unread_message_nums > 0"
              style="
                width: 5px;
                height: 5px;
                position: absolute;
                right: 10px;
                top: 10px;
                display: block;
                border-radius: 50%;
                background: #f00;
              "
            ></i>
          </span>
          <i
            v-if="$store.state.user.info.unread_message_nums > 0"
            style="
              width: 5px;
              height: 5px;
              position: absolute;
              right: -5px;
              top: 10px;
              display: block;
              border-radius: 50%;
              background: #f00;
            "
          ></i>
        </template>
        <el-menu-item index="/classList"> 班级列表</el-menu-item>
        <el-menu-item v-if="role === 1 || role === 6" index="/classMessage">
          班级消息

          <span
            v-if="this.$store.state.user.info.unread_message_nums"
            style="float: right; color: red"
          >
            <el-tag size="mini" type="danger"
              ><b>{{
                $store.state.user.info.unread_message_nums > 99
                  ? "99+"
                  : this.$store.state.user.info.unread_message_nums
              }}</b></el-tag
            >
          </span>
        </el-menu-item>
      </el-submenu>

      <el-submenu
        v-if="
          this.role == 1 || this.role == 6 || this.role == 2 || this.role == 4
        "
        :class="subMenuClass"
        index="13"
      >
        <template slot="title">
          <i class="el-icon-service"></i>
          <span class="item-title">听书管理</span>
        </template>
        <el-menu-item index="/audioBooks"> 音频书列表 </el-menu-item>
        <el-menu-item index="/cate"> 分类列表 </el-menu-item>
      </el-submenu>

      <el-submenu
        v-if="this.role == 1 || this.role == 6 || this.role == 2"
        :class="subMenuClass"
        index="14"
      >
        <template slot="title">
          <i class="el-icon-coordinate"></i>
          <span class="item-title">会员答题</span>
        </template>
        <el-menu-item index="/game"> 题目列表 </el-menu-item>
      </el-submenu>
      <el-submenu v-if="this.role == 1" :class="subMenuClass" index="15">
        <template slot="title">
          <i class="el-icon-coordinate"></i>
          <span class="item-title">推广</span>
        </template>
        <el-menu-item index="/withdraw"> 提现列表 </el-menu-item>
        <el-menu-item index="/promotionRecord"> 推广记录 </el-menu-item>
      </el-submenu>

      <el-submenu
        v-if="this.role == 1 || this.role == 7 || this.role == 8"
        :class="subMenuClass"
        index="16"
      >
        <template slot="title">
          <i class="el-icon-coordinate"></i>
          <span class="item-title">流量组</span>
        </template>
        <el-menu-item index="/customerCard"> 客户名片</el-menu-item>
        <el-menu-item index="/myData"> 我的数据</el-menu-item>
        <el-menu-item
          v-if="this.role == 1 || this.role == 8"
          index="/flowStatistics"
        >
          统计数据
        </el-menu-item>
        <el-menu-item v-if="this.role == 1" index="/flowCrew">
          组员设置
        </el-menu-item>
      </el-submenu>

      <el-submenu
        v-if="this.role == 1 || this.role == 7 || this.role == 8"
        :class="subMenuClass"
        index="17"
      >
        <template slot="title">
          <i class="el-icon-notebook-1"></i>
          <span class="item-title">诗词</span>
        </template>
        <el-menu-item index="/listAuthor"> 作者 </el-menu-item>
        <el-menu-item index="/listType"> 类型 </el-menu-item>
        <el-menu-item index="/listDynasty"> 朝代 </el-menu-item>
        <el-menu-item index="/listPoem"> 诗词 </el-menu-item>
      </el-submenu>

      <el-submenu :class="subMenuClass" index="18" v-if="this.role !== 33 ">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span class="item-title"
            >档案处
            <i
              v-if="this.$store.state.user.info.un_visit_nums > 0"
              style="
                width: 5px;
                height: 5px;
                position: absolute;
                right: 10px;
                top: 10px;
                display: block;
                border-radius: 50%;
                background: #f00;
              "
            ></i
          ></span>
          <i
            v-if="this.$store.state.user.info.un_visit_nums > 0"
            style="
              width: 5px;
              height: 5px;
              position: absolute;
              right: -5px;
              top: 10px;
              display: block;
              border-radius: 50%;
              background: #f00;
            "
          ></i>
        </template>
        <el-menu-item index="/studentNameCard" > 学员档案
          <span style="float: right; color: red">
            <el-tag size="mini" type="danger"
              ><b>{{
                this.$store.state.user.info.un_visit_nums > 99
                  ? "99+"
                  : this.$store.state.user.info.un_visit_nums
              }}</b></el-tag
            >
          </span>
        </el-menu-item>
      </el-submenu>

      <el-submenu v-if="role === 1" :class="subMenuClass" index="19">
        <template slot="title">
          <i class="el-icon-s-promotion"></i>
          <span class="item-title">邮件管理</span>
        </template>
        <el-menu-item index="/accessory">邮件附件</el-menu-item>
        <el-menu-item index="/send">邮件发送</el-menu-item>
        <el-menu-item index="/sendRecord">发送记录</el-menu-item>
      </el-submenu>
      <el-submenu v-if="role === 1 || role === 33" :class="subMenuClass" index="20">
        <template slot="title">
          <i class="el-icon-school"></i>
          <span class="item-title">线下合作</span>
        </template>
        <el-menu-item index="/campus">合作校区</el-menu-item>
        <el-menu-item index="/campusRecord">校区记录</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    menuStatus: {
      default: false,
    },
  },
  name: "left",
  data() {
    return {
      activeIndex: "",
      // role: 0,
      // userId: 0,
      status: true,
      leftWidth: 60,
      subMenuClass: "el-menu-item",
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.user.info.role,
      userId: (state) => state.user.info.id,
    }),
  },
  watch: {
    menuStatus() {
      console.log(this.menuStatus);
      this.status = this.menuStatus;

      if (this.status) {
        this.leftWidth = 60;
        this.subMenuClass = "el-menu-item";
      } else {
        this.leftWidth = 230;
        this.subMenuClass = "";
      }
    },
  },
  methods: {
    selectMenu() {
      // console.log(index)
      // console.log(indexPath)
    },
  },
  mounted() {
    // this.getUserInfo().then(res => {
    //   this.role = res.data.role
    //   this.userId = res.data.id
    //   this.$cookies.set('userId', this.userId)
    //   this.$cookies.set('role', this.role)
    // })

    this.activeIndex = this.$router.history.current.fullPath;
  },
};
</script>

<style>
.el-menu-left-side .el-submenu .el-submenu__title {
  padding: 0;
}

.el-menu-item a {
  display: inline-block;
  width: 100%;
  color: #303133 !important;
}

.hide-menu {
  width: 60px;
}

.show-menu {
  width: 230px;
}

.item-title {
  font-size: 14px;
}

.el-aside {
  overflow: auto !important;
}

.el-aside::-webkit-scrollbar {
  width: 0 !important;
}
</style>